import { useState, Fragment } from 'react'

import { Listbox, Transition } from '@headlessui/react'

import { ChevronDownIcon, LanguageIcon } from '@heroicons/react/24/outline'

import clsx from 'clsx'
import Image from 'next/image'
import { useRouter } from 'next/router'

import { preparePathname } from '@/utils/locale'

const localeOptions = [
  { value: 'pl', label: 'Polski', flag: '/assets/pl.svg' },
  { value: 'uk', label: 'Українська', flag: '/assets/ua.svg' },
  { value: 'en', label: 'English', flag: '/assets/gb.svg' },
]

export const LanguageSwitcher = () => {
  const router = useRouter()
  const { pathname, asPath, query } = router
  const [selectedLocale, setSelectedLocale] = useState(router.locale)

  const handleLocaleChange = (locale) => {
    setSelectedLocale(locale)
    router.push(
      { pathname: preparePathname(pathname, locale), query },
      preparePathname(asPath, locale),
      { locale }
    )
  }

  return (
    <Listbox value={selectedLocale} onChange={handleLocaleChange}>
      <div className="relative">
        <Listbox.Button className="relative grid w-full cursor-pointer grid-cols-2 items-center rounded-sm p-2 focus:outline-none focus-visible:border-violet-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300">
          <LanguageIcon className="h-5 w-5" />
          <ChevronDownIcon className="h-4 w-4 text-violet-900/80" />
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-sm bg-gray-100 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {localeOptions.map((locale) => (
              <Listbox.Option
                key={locale.value}
                value={locale.value}
                as={Fragment}
                className={({ selected, active }) =>
                  clsx('relative cursor-pointer select-none p-2', {
                    'bg-amber-100': active,
                    'bg-violet-200': selected,
                  })
                }
              >
                {() => (
                  <li>
                    <Image
                      src={locale.flag}
                      alt={locale.label}
                      width="24"
                      height="18"
                      className="m-auto"
                    />
                  </li>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}
