import { useState } from 'react'

import { Dialog, Popover } from '@headlessui/react'

import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

import { useTranslation } from 'next-i18next'
import Link from 'next/link'

import { Button } from '@/components/Button'
import { LanguageSwitcher } from '@/components/LanguageSwitcher'
import { Logo } from '@/components/Logo'
import { ENV } from '@/constants/env'
import { getTranslatedNavLinks } from '@/constants/links'
import { NavLinkDesktop } from '@/features/layout/components/elements/NavLinkDesktop'
import { NavLinkMobile } from '@/features/layout/components/elements/NavLinkMobile'

export const NavigationBar = () => {
  const { t: tf } = useTranslation('features')
  const { t: tl } = useTranslation('landing')
  const { t: tn } = useTranslation('nav')
  const { t: tc } = useTranslation('comparisons')
  const { t: t } = useTranslation('generic')

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const navLinks = getTranslatedNavLinks(tn, tl, tf, tc, t)

  return (
    <header className="fixed top-0 isolate z-[100] w-full border-b-violet-100 bg-white shadow-sm shadow-violet-100">
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8">
        <div className="flex lg:flex-1">
          <Link href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Estetify</span>
            <Logo className="h-8 w-auto fill-inherit" />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <LanguageSwitcher />
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5  text-violet-950/80"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            {mobileMenuOpen ? (
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            ) : (
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            )}
          </button>
        </div>
        <Popover.Group className="hidden items-center lg:flex lg:gap-x-12">
          {navLinks.map((link) => (
            <NavLinkDesktop key={link.title} link={link} />
          ))}
        </Popover.Group>
        <div className="hidden items-center gap-4 lg:flex lg:flex-1 lg:justify-end">
          <LanguageSwitcher />
          <Link
            href={ENV.APP_URL}
            target={'_blank'}
            rel="noreferrer"
            id="navigation-bar_open-app"
          >
            <Button>{tl('landing.openApp')} &rarr;</Button>
          </Link>
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-violet-900/10">
          <div className="tutajpowinnobyc z-[200] flex items-center justify-between">
            <Link href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Estetify</span>
              <Logo className="h-8 w-auto fill-inherit" />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5  text-violet-950/80"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-12 flow-root text-violet-950">
            <div className="-my-6 divide-y divide-violet-500/10">
              <div className="space-y-2 py-6">
                {navLinks.map((navLink) => (
                  <NavLinkMobile key={navLink.title} link={navLink} />
                ))}
              </div>
              <div className="py-6">
                <Link
                  href={ENV.APP_URL}
                  target={'_blank'}
                  rel="noreferrer"
                  id="navigation-bar_open-app"
                >
                  <Button>{tl('landing.openApp')} &rarr;</Button>
                </Link>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}
