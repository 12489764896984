export const SOURCES = {
  open: {
    hero: {
      android: 'hero_open-android',
      ios: 'hero_open-ios',
    },
    nav: {
      android: 'nav_open-android',
      ios: 'nav_open-ios',
    },
    footer: {
      android: 'footer_open-android',
      ios: 'footer_open-ios',
    },
  },
}
