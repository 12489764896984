import { Fragment } from 'react'

import { Popover, Transition } from '@headlessui/react'

import { ChevronDownIcon } from '@heroicons/react/24/outline'

import Link from 'next/link'

import { MobileAppButtons } from '@/components/MobileAppButtons'
import { NavLink } from '@/constants/links'

type NavLinkProps = {
  link: NavLink
}

export const NavLinkDesktop = ({ link }: NavLinkProps) => {
  if (link.onlyFooter) {
    return null
  }

  switch (link.type) {
    case 'features':
      return (
        <Popover>
          <Popover.Button className="flex items-center gap-x-2 text-sm font-semibold leading-6  text-violet-950">
            {link.icon && <link.icon className="h-4 w-4" />}
            {link.title}
            <ChevronDownIcon
              className="h-5 w-5 flex-none  text-violet-950/60"
              aria-hidden="true"
            />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 -translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-1"
          >
            <Popover.Panel className="absolute inset-x-0 top-0 -z-10 bg-white pt-14 shadow-lg ring-1 ring-violet-300/50">
              <div className="mx-auto grid max-w-7xl grid-cols-4 gap-x-4 px-6 py-10 lg:px-8 xl:gap-x-8">
                {link?.items &&
                  link.items.map((item) => (
                    <div
                      key={item.name}
                      className="group relative rounded-lg from-purple-400/5 via-violet-200/5 to-violet-50 p-6 text-sm leading-6 outline-violet-50 transition-all hover:bg-violet-50/25 hover:bg-gradient-to-bl hover:shadow-sm hover:outline"
                    >
                      <div className="flex h-11 w-11 items-center justify-center rounded-lg  bg-gradient-to-bl from-violet-500/20 via-violet-200/25 to-violet-200/20 transition-all group-hover:from-violet-500/25 group-hover:to-violet-200/25">
                        {item?.icon && (
                          <item.icon
                            className="h-6 w-6  text-violet-950/80"
                            aria-hidden="true"
                          />
                        )}
                      </div>
                      <Link
                        href={item.href}
                        className="mt-6 block font-semibold  text-violet-950"
                      >
                        {item.name}
                        <span className="absolute inset-0" />
                      </Link>
                      <p className="mt-1  text-violet-950/80">
                        {item.description}
                      </p>
                    </div>
                  ))}
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      )
    case 'product':
      return (
        <Popover>
          <Popover.Button className="flex items-center gap-x-2 text-sm font-semibold leading-6  text-violet-950">
            {link.icon && <link.icon className="h-4 w-4" />}
            {link.title}
            <ChevronDownIcon
              className="h-5 w-5 flex-none  text-violet-950/60"
              aria-hidden="true"
            />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 -translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-1"
          >
            <Popover.Panel className="absolute inset-x-0 top-0 -z-10 bg-white pt-14 shadow-lg ring-1 ring-violet-300/50">
              <div className="mx-auto grid max-w-7xl grid-cols-2 gap-x-4 px-6 py-10 lg:px-8 xl:gap-x-8">
                {link.product && (
                  <div>
                    <Link
                      href={link.product.big.href}
                      className="flex min-h-[16rem] min-w-[24rem] items-end rounded-xl bg-gradient-to-tl from-purple-800 via-violet-900 to-purple-800 p-4 shadow-2xl shadow-violet-900/50 transition-all hover:brightness-125"
                      key={link.product.big.name}
                    >
                      <div>
                        <p className="text-3xl font-semibold tracking-tighter text-white">
                          {link.product.big.name}
                        </p>
                        <p className="text-white">
                          {link.product.big.description}
                        </p>
                      </div>
                    </Link>
                    <div className="mt-8 flex items-center gap-6">
                      <MobileAppButtons source="nav" />
                    </div>
                  </div>
                )}
                <div>
                  {link?.items &&
                    link.items.map((item) => (
                      <div
                        key={item.name}
                        className="group relative rounded-lg from-purple-400/5 via-violet-200/5 to-violet-50 p-6 text-sm leading-6 outline-violet-50 transition-all hover:bg-violet-50/25 hover:bg-gradient-to-bl hover:shadow-sm hover:outline"
                      >
                        <Link
                          href={item.href}
                          className="flex items-center gap-2 font-semibold text-violet-950"
                        >
                          {item.icon && <item.icon className="h-5 w-5" />}
                          {item.name}
                          {item.badge && (
                            <div className="h-full max-h-fit max-w-fit rounded border border-violet-700 bg-violet-200 p-1 px-2 text-xs tracking-wider text-violet-700">
                              {item.badge.text}
                            </div>
                          )}
                          <span className="absolute inset-0" />
                        </Link>
                      </div>
                    ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      )
    default:
      return (
        <Link
          href={link.href ?? '#'}
          className="flex items-center gap-2 text-sm font-semibold leading-6  text-violet-950"
        >
          <link.icon className="h-4 w-4" />
          {link.title}
        </Link>
      )
  }
}
