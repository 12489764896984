import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { useRouter } from 'next/router'

import { ENV } from '@/constants/env'

import { SOURCES } from '../constants/analytics'

type MobileAppButtonsProps = {
  source: 'hero' | 'nav' | 'footer'
}

export const MobileAppButtons = ({ source }: MobileAppButtonsProps) => {
  const { locale } = useRouter()

  const { t } = useTranslation('landing')

  return (
    <>
      <a
        aria-label={t('landing.downloadOnAppStore')}
        target={'_blank'}
        rel="noreferrer"
        href={ENV.APP_STORE_URL}
        id={SOURCES.open[source].ios}
      >
        <Image
          width={140}
          height={50}
          src={`/${locale}/app-store.svg`}
          priority
          className="transition-all hover:opacity-80"
          alt={t('landing.downloadOnAppStore')}
        />
      </a>
      <a
        aria-label={t('landing.downloadOnGooglePlay')}
        target={'_blank'}
        rel="noreferrer"
        href={ENV.GOOGLE_PLAY_URL}
        id={SOURCES.open[source].android}
      >
        <Image
          width={151.5}
          height={65}
          priority
          src={`/${locale}/google-play-store.svg`}
          className="hover:opacity-80"
          alt={t('landing.downloadOnGooglePlay')}
        />
      </a>
    </>
  )
}
