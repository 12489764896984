import { Footer } from '@/components/Footer'
import { NavigationBar } from '@/features/layout/components/elements/NavigationBar'

export const LandingLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="divide-violet-200 border-violet-200 fill-violet-950 text-violet-950 caret-violet-400 outline-violet-400 ring-violet-400 ring-offset-violet-400 selection:bg-violet-200 selection:text-violet-950 focus-within:outline-violet-400 focus:outline-violet-400">
      <NavigationBar />
      <main>{children}</main>
      <Footer />
    </div>
  )
}
