import { useTranslation } from 'next-i18next'
import Link from 'next/link'

import { Container } from '@/components/Container'
import { Logomark } from '@/components/Logo'
import { MobileAppButtons } from '@/components/MobileAppButtons'
import { getTranslatedNavLinks } from '@/constants/links'

export const Footer = () => {
  const { t: tl } = useTranslation('landing')
  const { t: t } = useTranslation('generic')
  const { t: tn } = useTranslation('nav')
  const { t: tf } = useTranslation('features')
  const { t: tc } = useTranslation('comparisons')

  const navLinks = getTranslatedNavLinks(tn, tl, tf, tc, t)

  return (
    <footer className="border border-t">
      <Container>
        <div className="flex flex-col items-start justify-between gap-y-12 pb-6 pt-16 lg:flex-row lg:items-start lg:py-16">
          <div className="w-full">
            <div className="flex items-center">
              <Logomark className="flex-non h-10 w-10 fill-white" />
              <div className="ml-4">
                <p className="text-base font-semibold">Estetify</p>
                <p className="mt-1 text-sm">{t('generic.freeYourTime')}</p>
              </div>
            </div>
            <nav className="grid gap-4 py-4 md:grid-cols-2">
              {navLinks.map(
                (link) =>
                  link.items && (
                    <div key={link.title} className="py-4">
                      <h3 className="inline-flex items-center gap-2 pb-2 text-lg font-semibold tracking-tight">
                        <link.icon className="h-5 w-5" />
                        {link.title}
                      </h3>

                      <ul className="grid gap-2">
                        {link.items.map((item) => (
                          <li
                            key={item.name}
                            className="inline-flex items-center gap-2 transition-all hover:text-violet-800"
                          >
                            {item.icon && (
                              <item.icon className="min-w-4 h-4 w-4" />
                            )}

                            <Link href={item.href}>{item.name}</Link>
                            {item.badge && (
                              <div className="h-full max-h-fit max-w-fit rounded border border-violet-700 bg-violet-200 p-1 px-2 text-xs tracking-wider text-violet-700">
                                {item.badge.text}
                              </div>
                            )}
                          </li>
                        ))}
                        <li>
                          {link.product && (
                            <div className="my-4">
                              <Link
                                href={link.product.big.href}
                                className="flex items-end rounded-xl bg-gradient-to-tl from-purple-800 via-violet-900 to-purple-800 p-4 py-4 shadow-2xl shadow-violet-900/40 transition-all hover:brightness-125"
                                key={link.product.big.name}
                              >
                                <div>
                                  <p className="text-lg font-semibold tracking-tighter text-white">
                                    {link.product.big.name}
                                  </p>
                                  <p className="text-white">
                                    {link.product.big.description}
                                  </p>
                                </div>
                              </Link>
                            </div>
                          )}
                        </li>
                      </ul>
                    </div>
                  )
              )}
            </nav>
          </div>
          <div className="relative flex w-full flex-col justify-start border-t pb-2 pt-6 lg:col-span-7 lg:mt-0 lg:max-w-sm lg:items-end lg:border-none lg:pb-0 lg:pt-0 xl:col-span-6">
            <p className="text-left text-sm font-semibold lg:content-start lg:text-right">
              {tl('landing.appDownload')}
              <span className="lg:hidden">:</span>
            </p>
            <div className="mt-4 flex items-center justify-start gap-x-5 lg:mx-0 lg:justify-end">
              <MobileAppButtons source="footer" />
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col items-center border-t pb-12 pt-8 text-center md:flex-row-reverse md:justify-between md:pt-6">
          <p className="mr-auto mt-6 text-sm text-violet-950/80 md:mt-0 lg:ml-auto lg:mr-0">
            Estetify Sp. z o.o. &copy; Copyright {new Date().getFullYear()}.{' '}
            {tl('landing.allRightsReserved')}
          </p>
        </div>
      </Container>
    </footer>
  )
}
