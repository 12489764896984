import {
  AcademicCapIcon,
  BoltIcon,
  CalendarDaysIcon,
  ClipboardDocumentCheckIcon,
  CubeIcon,
  CurrencyDollarIcon,
  DevicePhoneMobileIcon,
  LinkIcon,
  NewspaperIcon,
  PhoneIcon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  SparklesIcon,
  StarIcon,
} from '@heroicons/react/24/outline'

import { TFunction } from 'next-i18next'

import { IDS } from '@/constants/ids'
import { Icon } from '@/models'

export const LINKS = {
  FEATURES_CALENDAR: '/features/calendar',
  FEATURES_SMS: '/features/messages',
  FEATURES_SELF_BOOKING: '/features/self-booking',
  FEATURES_MANAGEMENT: '/features/management',
  CHANGELOG: '/changelog',
  BLOG: '/blog',
  COMPARISONS_BOOKSY: '/comparison-with-booksy',
  CONTACT: '/#contact-us',
  PRIVACY_POLICY: '/documents/privacy-policy',
  REGULATIONS: '/documents/regulations',
  FACEBOOK: 'https://www.facebook.com/estetify',
  FACEBOOK_GROUP: 'https://www.facebook.com/groups/1252890271990286',
  INSTAGRAM: 'https://www.instagram.com/estetify.pl/',
}

export type NavLink = {
  type: 'features' | 'product' | 'default' | 'blog'
  onlyFooter?: boolean
  title: string
  icon: Icon
  href?: string
  items?: {
    name: string
    description?: string
    href: string
    icon?: Icon
    meta?: Record<string, any>
    badge?: {
      text: string
    }
  }[]
  product?: {
    big: {
      name: string
      description?: string
      href: string
    }
  }
}

export const getTranslatedNavLinks = (
  tn: TFunction,
  tl: TFunction,
  tf: TFunction,
  tc: TFunction,
  t: TFunction
): NavLink[] => [
  {
    type: 'features',
    icon: SparklesIcon,
    title: tn('nav.features'),
    items: [
      {
        name: tf('features.calendarAndVisits.clearCalendar.heading'),
        description: tl('landing.keyFeatures.calendar.descriptionShort'),
        href: LINKS.FEATURES_CALENDAR,
        icon: CalendarDaysIcon,
      },
      {
        name: tl('landing.keyFeatures.sms.titleShort'),
        description: tl('landing.keyFeatures.sms.descriptionShort'),
        href: LINKS.FEATURES_SMS,
        icon: DevicePhoneMobileIcon,
      },
      {
        name: tl('landing.keyFeatures.selfBooking.title'),
        description: tl('landing.keyFeatures.selfBooking.descriptionShort'),
        href: LINKS.FEATURES_SELF_BOOKING,
        icon: LinkIcon,
      },
      {
        name: tl('landing.keyFeatures.managament.titleShort'),
        description: tl('landing.keyFeatures.managament.descriptionShort'),
        href: LINKS.FEATURES_MANAGEMENT,
        icon: ClipboardDocumentCheckIcon,
      },
    ],
  },
  {
    type: 'product',
    title: tn('nav.product'),
    icon: CubeIcon,
    product: {
      big: {
        name: tc('comparisons.booksy.headingShort'),
        description: tc('comparisons.booksy.description'),
        href: LINKS.COMPARISONS_BOOKSY,
      },
    },
    items: [
      {
        name: tl('landing.secondaryFeatures.title'),
        icon: StarIcon,
        href: '#',
      },
      {
        name: tn('nav.changelog'),
        icon: BoltIcon,
        href: LINKS.CHANGELOG,
        badge: {
          text: new Date('2024-02-20').toLocaleDateString('pl-PL', {
            day: 'numeric',
            month: 'long',
          }),
        },
      },
      {
        name: tn('nav.pricing'),
        href: `/#${IDS.PRICING}`,
        icon: CurrencyDollarIcon,
      },
      {
        name: tn('nav.faq'),
        icon: QuestionMarkCircleIcon,
        href: `/#${IDS.FAQS}`,
      },
      {
        name: tn('nav.contact'),
        icon: PhoneIcon,
        href: LINKS.CONTACT,
      },
    ],
  },
  {
    type: 'blog',
    title: tn('nav.blog'),
    icon: NewspaperIcon,
    href: LINKS.BLOG,
    // TODO: add blog posts dynamically depending on locale
    items: [
      {
        name: 'Wymogi sanitarne dla salonu kosmetycznego – czego wymaga sanepid?',
        href: '/pl/blog/wymogi-sanitarne-dla-salonu-kosmetycznego-czego-wymaga-sanepid',
      },
      {
        name: 'Marketing w branży beauty. Na co zwrócić uwagę w działaniach marketingowych swojego salonu?',
        href: '/pl/blog/marketing-w-branzy-beauty-na-co-zwrocic-uwage-w-dzialaniach-marketingowych-swojego-salonu',
      },
      {
        name: 'Jak podnieść ceny w salonie kosmetycznym i nie stracić klientów?',
        href: '/pl/blog/jak-podniesc-ceny-w-salonie-kosmetycznym-i-nie-stracic-klientow',
      },
    ],
  },
  {
    type: 'default',
    onlyFooter: true,
    title: t('generic.other'),
    icon: AcademicCapIcon,
    items: [
      {
        name: tn('nav.regulations'),
        href: LINKS.REGULATIONS,
        icon: ScaleIcon,
      },
      {
        name: tn('nav.privacyPolicy'),
        href: LINKS.PRIVACY_POLICY,
        icon: ScaleIcon,
      },
      {
        name: tn('nav.facebookGroup'),
        href: LINKS.FACEBOOK,
        icon: LinkIcon,
      },
      {
        name: 'Facebook',
        href: LINKS.FACEBOOK_GROUP,
        icon: LinkIcon,
      },
      {
        name: 'Instagram',
        href: LINKS.INSTAGRAM,
        icon: LinkIcon,
      },
    ],
  },
]

export const SECTIONS_LINKS = {
  PRICING: `/#${IDS.PRICING}`,
  PRICING_MANAGEMENT: `/#${IDS.PRICING_MANAGEMENT}`,
  PRICING_FOR_CLIENTS: `/#${IDS.PRICING_FOR_CLIENTS}`,
  PRICING_COMFORT: `/#${IDS.PRICING_COMFORT}`,
  PRICING_BRANDING: `/#${IDS.PRICING_BRANDING}`,
  CONTACT_US: `/#${IDS.CONTACT_US}`,
  SECONDARY_FEATURES: `/#${IDS.SECONDARY_FEATURES}`,
}
