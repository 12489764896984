export const IDS = {
  PRICING: 'pricing',
  PRICING_MANAGEMENT: 'pricing-management',
  PRICING_FOR_CLIENTS: 'pricing-for-clients',
  PRICING_BRANDING: 'pricing-branding',
  PRICING_COMFORT: 'pricing-comfort',
  CONTACT_US: 'contact-us',
  SECONDARY_FEATURES: 'secondary-features',
  FAQS: 'faqs',
}
