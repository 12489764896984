import { Fragment } from 'react'

import { Disclosure } from '@headlessui/react'

import { ChevronDownIcon } from '@heroicons/react/24/outline'

import clsx from 'clsx'
import Link from 'next/link'

import { MobileAppButtons } from '@/components/MobileAppButtons'
import { NavLink } from '@/constants/links'

type NavLinkProps = {
  link: NavLink
}

export const NavLinkMobile = ({ link }: NavLinkProps) => {
  if (link.onlyFooter) {
    return null
  }

  switch (link.type) {
    case 'features':
      return (
        <Disclosure as="div" className="-mx-3">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7  hover:bg-violet-50">
                <div className="flex items-center gap-2">
                  {link.icon && <link.icon className="h-5 w-5" />}
                  {link.title}
                </div>
                <ChevronDownIcon
                  className={clsx(
                    open ? 'rotate-180' : '',
                    'h-5 w-5 flex-none'
                  )}
                  aria-hidden="true"
                />
              </Disclosure.Button>
              <Disclosure.Panel className="mt-2 space-y-2">
                {link?.items &&
                  link.items.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as={Link}
                      href={item.href}
                      className="block rounded-lg py-2 pl-6 pr-3 leading-7  hover:bg-violet-50"
                    >
                      <p className="font-sembiold">{item.name}</p>
                      <p className="text-sm  text-violet-950/80">
                        {item.description}
                      </p>
                    </Disclosure.Button>
                  ))}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      )
    case 'product':
      return (
        <Disclosure as="div" className="-mx-3">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7  hover:bg-violet-50">
                <div className="flex items-center gap-2">
                  {link.icon && <link.icon className="h-5 w-5" />}
                  {link.title}
                </div>
                <ChevronDownIcon
                  className={clsx(
                    open ? 'rotate-180' : '',
                    'h-5 w-5 flex-none'
                  )}
                  aria-hidden="true"
                />
              </Disclosure.Button>
              <Disclosure.Panel className="mt-2 space-y-2">
                {link.product && (
                  <div>
                    <Link
                      href={link.product.big.href}
                      className="mx-2 my-4 flex min-h-[4rem] items-end rounded-xl bg-gradient-to-tl from-purple-800 via-violet-900 to-purple-800 p-4 shadow-2xl shadow-violet-900/50 transition-all hover:brightness-125"
                      key={link.product.big.name}
                    >
                      <div>
                        <p className="text-3xl font-semibold tracking-tighter text-white">
                          {link.product.big.name}
                        </p>
                        <p className="text-white">
                          {link.product.big.description}
                        </p>
                      </div>
                    </Link>
                  </div>
                )}
                {link?.items &&
                  link.items.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as={Link}
                      href={item.href}
                      className="flex gap-2 rounded-lg py-2 pl-6 pr-3  leading-7 hover:bg-violet-50"
                    >
                      <p className="font-sembiold">{item.name}</p>
                      {item.badge && (
                        <div className="h-full max-h-fit max-w-fit rounded border border-violet-700 bg-violet-200 p-1 px-2 text-xs tracking-wider text-violet-700">
                          {item.badge.text}
                        </div>
                      )}
                    </Disclosure.Button>
                  ))}
                <div className="mt-8 flex items-center justify-center gap-4">
                  <MobileAppButtons source="nav" />
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      )
    default:
      return (
        <Link
          href={link.href ?? '#'}
          className="-mx-3 flex items-center gap-2 rounded-lg px-3 py-2 text-base font-semibold leading-7  hover:bg-violet-50"
        >
          <link.icon className="h-5 w-5" />
          {link.title}
        </Link>
      )
  }
}
