import { ReactNode, ButtonHTMLAttributes } from 'react'

import clsx from 'clsx'
import Link from 'next/link'

export type ButtonProps = ButtonHTMLAttributes<
  HTMLButtonElement | HTMLAnchorElement
> & {
  children: ReactNode
  startIcon?: ReactNode
  endIcon?: ReactNode
  className?: string
  to?: string
  variant?:
    | 'primary'
    | 'secondary'
    | 'warning'
    | 'white'
    | 'inline-primary'
    | 'inline-secondary'
    | 'inline-warning'
    | 'neutral'
    | 'chip'
  size?: 'small' | 'medium' | 'large'
  fullWidth?: boolean
}

const ButtonInner = ({
  children,
  startIcon,
  endIcon,
  size,
  variant,
}: Pick<
  ButtonProps,
  'children' | 'startIcon' | 'endIcon' | 'size' | 'variant'
>) => (
  <>
    {startIcon && (
      <span
        className={clsx('mr-2 inline', {
          'h-3 w-3': size === 'small',
          'h-4 w-4': size === 'medium',
          'h-5 w-5': size === 'large',
          '-ml-1': !variant?.includes('inline-'),
        })}
        aria-hidden="true"
      >
        {startIcon}
      </span>
    )}
    {children}
    {endIcon && (
      <span
        className={clsx('ml-2 inline', {
          'h-3 w-3': size === 'small',
          'h-4 w-4': size === 'medium',
          'h-5 w-5': size === 'large',
          'ml-1': !variant?.includes('inline-'),
        })}
        aria-hidden="true"
      >
        {endIcon}
      </span>
    )}
  </>
)

export const Button = ({
  children,
  startIcon,
  endIcon,
  variant = 'primary',
  size = 'medium',
  className,
  to,
  fullWidth = false,
  ...delegated
}: ButtonProps) => {
  const isLink = !!to
  const classes = clsx(
    variant === 'neutral'
      ? ''
      : 'whitespace-nowrap leading-6 lex cursor-pointer flex items-center justify-center rounded transition-all font-medium focus:outline-none disabled:cursor-not-allowed',
    {
      'bg-violet-600 text-white hover:bg-violet-700 focus:ring-violet-700 border-transparent':
        variant === 'primary',
      'rounded disabled:opacity-30 disabled:text-gray-500 disabled:border-gray-700 bt-transparent text-violet-600 hover:bg-violet-700 hover:text-white border-violet-600 focus:ring-violet-700':
        variant === 'secondary',
      'disabled:opacity-30 disabled:text-gray-500 bt-transparent text-red-600 hover:bg-red-600 hover:text-white border-red-600 focus:ring-red-700':
        variant === 'warning',
      'bg-white text-violet-900 hover:bg-gray-100 focus:ring-gray-100 border-transparent':
        variant === 'white',
      'text-violet-600 hover:text-violet-700': variant === 'inline-primary',
      'text-red-600 hover:text-red-700': variant === 'inline-warning',
      '': variant === 'inline-secondary',
      'text-sm': size === 'small',
      'py-1 px-3':
        size === 'small' &&
        ['primary', 'secondary', 'warning', 'white'].includes(variant),
      'text-md': size === 'medium',
      'py-2 px-4':
        size === 'medium' &&
        ['primary', 'secondary', 'warning', 'white'].includes(variant),
      'text-lg': size === 'large',
      'py-3 px-6':
        size === 'large' &&
        ['primary', 'secondary', 'warning', 'white'].includes(variant),
      'p-0 disabled:text-gray-500': variant.includes('inline-'),
      'shadow-sm border focus:ring-2 focus:ring-offset-2 disabled:bg-gray-300':
        ['primary', 'secondary', 'warning'].includes(variant),
      'w-full':
        fullWidth &&
        ['primary', 'secondary', 'warning', 'neutral', 'white'].includes(
          variant
        ),
      'bg-gray-100 px-3 py-2 rounded-sm text-sm text-gray-500 h-fit hover:bg-gray-200':
        variant === 'chip',
    },
    className
  )

  return isLink ? (
    <Link href={to} className={classes} {...delegated}>
      <ButtonInner size={size} variant={variant}>
        {children}
      </ButtonInner>
    </Link>
  ) : (
    <button className={classes} type="button" {...delegated}>
      <ButtonInner size={size} variant={variant}>
        {children}
      </ButtonInner>
    </button>
  )
}
